var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.stockMovement.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.stockMovement.id},on:{"organizationChanged":(organizationId) => {
            _vm.stockMovement.organization.id = organizationId;
            _vm.stockMovement.allowedLocations = [];
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.stockMovement.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.stockMovement.organization.id},on:{"locationsChanged":(locations) => {
            _vm.stockMovement.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')} (*)`,"placeholder":_vm.$t('COMMON.TYPE')}},[_c('el-select',{attrs:{"name":`${_vm.$t('COMMON.TYPE')}`,"placeholder":_vm.$t('COMMON.TYPE')},on:{"onchange":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.stockMovement.movement_type),callback:function ($$v) {_vm.$set(_vm.stockMovement, "movement_type", $$v)},expression:"stockMovement.movement_type"}},_vm._l((_vm.typesOptions),function(value){return _c('el-option',{key:value,attrs:{"value":value,"label":_vm.$t(`STOCK_MOVEMENTS.TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"form-wrapper full"},[(
        _vm.stockMovement.movement_type === _vm.TYPE_REMOVE ||
        _vm.stockMovement.movement_type === _vm.TYPE_MOVE ||
        _vm.stockMovement.movement_type === _vm.TYPE_CORRECT
      )?_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.SOURCE_WAREHOUSE`)} (*)`}},[_c('warehouse-selector',{attrs:{"allowNone":true,"warehouse":_vm.stockMovement.sourceWarehouse
            ? _vm.stockMovement.sourceWarehouse.id
            : null,"filterable":true,"showAll":false,"filterOrganization":_vm.stockMovement.organization.id},on:{"warehouseChanged":(warehouseId) => {
            _vm.stockMovement.sourceWarehouse.id = warehouseId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.sourceWarehouse}})],1),_c('div',{staticClass:"form-wrapper full"},[(
        _vm.stockMovement.movement_type === _vm.TYPE_ADD ||
        _vm.stockMovement.movement_type === _vm.TYPE_MOVE
      )?_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.DESTINATION_WAREHOUSE`)} (*)`}},[_c('warehouse-selector',{attrs:{"allowNone":true,"warehouse":_vm.stockMovement.destinationWarehouse
            ? _vm.stockMovement.destinationWarehouse.id
            : null,"filterable":true,"showAll":false,"filterOrganization":_vm.stockMovement.organization.id},on:{"warehouseChanged":(warehouseId) => {
            _vm.stockMovement.destinationWarehouse.id = warehouseId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.destinationWarehouse}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('h2',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.stockMovement.excerpt),callback:function ($$v) {_vm.$set(_vm.stockMovement, "excerpt", $$v)},expression:"stockMovement.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stockMovement.id ? _vm.$t("STOCK_MOVEMENTS.EDIT_STOCK_MOVEMENT") : _vm.$t("STOCK_MOVEMENTS.ADD_STOCK_MOVEMENT"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }