export default {
  type: "stock-movements",
  movement_type: null,
  excerpt: "",
  relationshipNames: [
    "organization",
    "allowedLocations",
    "sourceWarehouse",
    "destinationWarehouse",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  allowedLocations: [],
  sourceWarehouse: {
    type: "warehouses",
    id: null,
  },
  destinationWarehouse: {
    type: "warehouses",
    id: null,
  },
  items: [],
};
