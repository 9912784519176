<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <base-input
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :allowNone="true"
          :organization="stockMovement.organization.id"
          :filterable="true"
          :showAll="false"
          :disabled="!!stockMovement.id"
          @organizationChanged="
            (organizationId) => {
              stockMovement.organization.id = organizationId;
              stockMovement.allowedLocations = [];
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
      >
        <locations-selector
          :locations="stockMovement.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="stockMovement.organization.id"
          @locationsChanged="
            (locations) => {
              stockMovement.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.TYPE')} (*)`"
        :placeholder="$t('COMMON.TYPE')"
      >
        <el-select
          :name="`${$t('COMMON.TYPE')}`"
          :placeholder="$t('COMMON.TYPE')"
          v-model="stockMovement.movement_type"
          @onchange="
            () => {
              onFormChanged();
            }
          "
        >
          <el-option
            v-for="value in typesOptions"
            :key="value"
            :value="value"
            :label="$t(`STOCK_MOVEMENTS.TYPE_${value}`)"
          />
        </el-select>
      </base-input>
    </div>

    <div class="form-wrapper full">
      <base-input
        v-if="
          stockMovement.movement_type === TYPE_REMOVE ||
          stockMovement.movement_type === TYPE_MOVE ||
          stockMovement.movement_type === TYPE_CORRECT
        "
        :label="`${$t(`COMMON.SOURCE_WAREHOUSE`)} (*)`"
      >
        <warehouse-selector
          :allowNone="true"
          :warehouse="
            stockMovement.sourceWarehouse
              ? stockMovement.sourceWarehouse.id
              : null
          "
          :filterable="true"
          :showAll="false"
          :filterOrganization="stockMovement.organization.id"
          @warehouseChanged="
            (warehouseId) => {
              stockMovement.sourceWarehouse.id = warehouseId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.sourceWarehouse" />
    </div>

    <div class="form-wrapper full">
      <base-input
        v-if="
          stockMovement.movement_type === TYPE_ADD ||
          stockMovement.movement_type === TYPE_MOVE
        "
        :label="`${$t(`COMMON.DESTINATION_WAREHOUSE`)} (*)`"
      >
        <warehouse-selector
          :allowNone="true"
          :warehouse="
            stockMovement.destinationWarehouse
              ? stockMovement.destinationWarehouse.id
              : null
          "
          :filterable="true"
          :showAll="false"
          :filterOrganization="stockMovement.organization.id"
          @warehouseChanged="
            (warehouseId) => {
              stockMovement.destinationWarehouse.id = warehouseId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.destinationWarehouse" />
    </div>

    <div class="form-wrapper full">
      <h2 class="mt-2">{{ $t("COMMON.NOTE") }}</h2>
      <html-editor v-model="stockMovement.excerpt" @change="onFormChanged()">
      </html-editor>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          stockMovement.id
            ? $t("STOCK_MOVEMENTS.EDIT_STOCK_MOVEMENT")
            : $t("STOCK_MOVEMENTS.ADD_STOCK_MOVEMENT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import { ORDER_STATUS_VALIDATED } from "@/constants/orders";
import {
  typesOptions,
  TYPE_ADD,
  TYPE_REMOVE,
  TYPE_MOVE,
  TYPE_CORRECT,
} from "@/constants/stockMovements";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    HtmlEditor,
    WarehouseSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["stockMovementData", "formErrors", "loading"],

  data() {
    let stockMovementData = { ...this.stockMovementData };
    stockMovementData = this.$fillUserOrganizationData(stockMovementData);

    return {
      stockMovement: stockMovementData,
      typesOptions: typesOptions,
      TYPE_ADD: TYPE_ADD,
      TYPE_REMOVE: TYPE_REMOVE,
      TYPE_MOVE: TYPE_MOVE,
      TYPE_CORRECT: TYPE_CORRECT,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let stockMovementData = cloneDeep(this.stockMovement);
      stockMovementData = this.$fillUserOrganizationData(stockMovementData);
      if (!stockMovementData.sourceWarehouse?.id) {
        delete stockMovementData.sourceWarehouse;
      }
      if (!stockMovementData.destinationWarehouse?.id) {
        delete stockMovementData.destinationWarehouse;
      }
      this.$emit("stockMovementSubmitted", stockMovementData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    stockMovementData(stockMovementData) {
      if (stockMovementData) {
        this.stockMovement = {
          ...this.stockMovement,
          ...cloneDeep(stockMovementData),
        };
        if (!this.stockMovement.organization) {
          this.stockMovement.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
