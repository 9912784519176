<template>
  <badge :type="getBadgeType()">
    {{ $t(`STOCK_MOVEMENTS.TYPE_${stockMovement.movement_type}`) }}
  </badge>
</template>

<script>
import {
  typesOptions,
  TYPE_ADD,
  TYPE_REMOVE,
  TYPE_MOVE,
  TYPE_CORRECT,
} from "@/constants/stockMovements";

export default {
  name: "stock-movement-status-badge",

  components: {},

  mixins: [],

  props: ["stockMovement"],

  data() {
    return {
      typesOptions: typesOptions,
      TYPE_ADD: TYPE_ADD,
      TYPE_REMOVE: TYPE_REMOVE,
      TYPE_MOVE: TYPE_MOVE,
      TYPE_CORRECT: TYPE_CORRECT,
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.stockMovement.movement_type) {
        case TYPE_ADD:
          return "success";
        case TYPE_REMOVE:
          return "danger";
        case TYPE_MOVE:
          return "info";
        case TYPE_CORRECT:
          return "warning";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
