<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ stockMovement.code }}</h3>
    <div class="row">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(stockMovement.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="stockMovement.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="stockMovement.organization" />
          </dd>
        </dl>

        <dl class="row" v-if="stockMovement.sourceWarehouse">
          <dt class="col-sm-4">{{ $t("COMMON.SOURCE_WAREHOUSE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="stockMovement.sourceWarehouse" />
          </dd>
        </dl>

        <dl class="row" v-if="stockMovement.destinationWarehouse">
          <dt class="col-sm-4">{{ $t("COMMON.DESTINATION_WAREHOUSE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="stockMovement.destinationWarehouse" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TYPE") }}</dt>
          <dd class="col-sm-8">
            <stock-movement-movement-type-badge
              :stockMovement="stockMovement"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <stock-movement-status-badge :stockMovement="stockMovement" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="stockMovement.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="stockMovement.excerpt">
            <div v-html="stockMovement.excerpt"></div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import StockMovementMovementTypeBadge from "./StockMovementMovementTypeBadge.vue";
import StockMovementStatusBadge from "./StockMovementStatusBadge.vue";

export default {
  name: "stock-movement-view-global",

  components: {
    StockMovementStatusBadge,
    StockMovementMovementTypeBadge,
  },

  props: ["stockMovement"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    stockMovement(stockMovement) {},
  },
};
</script>
