export const STATUS_DRAFT = "DRAFT";
export const STATUS_CANCELED = "CANCELED";
export const STATUS_VALIDATED = "VALIDATED";

export const TYPE_ADD = "ADD";
export const TYPE_REMOVE = "REMOVE";
export const TYPE_MOVE = "MOVE";
export const TYPE_CORRECT = "CORRECT";

export const statusOptions = [STATUS_DRAFT, STATUS_CANCELED, STATUS_VALIDATED];

export const typesOptions = [TYPE_ADD, TYPE_REMOVE, TYPE_MOVE, TYPE_CORRECT];
